import React, { useEffect, useRef, useState } from "react";
import { FcRotateCamera } from "react-icons/fc";
import "./Cameracomponent.css";
import { imaylPackageCreate } from "../../Service/ImaylService";
import { IoClose } from "react-icons/io5";

const Cameracomponent = (props) => {
  const videoRef = useRef(null);
  const [image, setImage] = useState(null);
  const [base64, setBase64] = useState(""); // State for Base64 string
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [openCamera, setOpenCamera] = useState(true);
  const [apiData, setApidata] = useState(null);
  const [detailsdata, setDetailsdata] = useState(null);
  const [loading, setLoading] = useState(null);
  const userData = props.userData;
  const [cameraopen, setCameraOpen] = useState(false);

  const [payload, setPayload] = useState({
    CommonDataUpdate: {
      AppType: "Android",
      AppVersion: "1.0",
      CarrierTrackingNo: "",
      CompanyId: 4629510147,
      DeviceName: "RMX36867",
      LabelType: "3 * 2",
      Latitude: "17.563773",
      LoggedInLocation: "eshipjet",
      Longitude: "73.5399354",
      OSVersion: "14",
      PrefixFormat: "",
      PrefixRange: 5,
      SyncType: "Online",
      UpdatedBy: "U00097",
      UpdatedOn: "2024-07-17 22:02:19",
    },
    Items: [
      {
        Bin: "",
        CarrierTrackingNo: "",
        DeliveryLocation: "",
        InternalTrackingNo: "",
        Mailbox: "",
        Notes: "",
        PackageImages: {
          Image1: "",
          Image2: "",
          Image3: "",
          SignatureImage: "",
        },
        PackageTypeId: "",
        PoNo: "",
        ReasearchReason: "",
        RefCarrierTrackingNo: "",
        RefDate: "",
        ShipmentValue: "",
        StatusCode: "REC",
        StorageLocation: "",
        // Weight: "",
      },
    ],
    MasterData: {
      CarrierId: "",
      CarrierTrackingNo: "",
      PackageImages: {
        Image1: "",
        Image2: "",
        Image3: "",
      },
      RefCarrierTrackingNo: "",
      ShipFromAdd1: "",
      ShipFromAdd2: "",
      ShipFromBuilding: "",
      ShipFromCity: "",
      ShipFromCompanyName: "",
      ShipFromCountry: "",
      ShipFromEmail: "",
      ShipFromEmpID: "",
      ShipFromFname: "",
      ShipFromLname: "",
      ShipFromLocaion: "",
      ShipFromMailStop: "",
      ShipFromPhoneNo: "",
      ShipFromState: "",
      ShipFromZipCode: "",
      ShipToAdd1: "",
      ShipToAdd2: "",
      ShipToAliasName: "",
      ShipToBuilding: "",
      ShipToCity: "",
      ShipToCompanyName: "",
      ShipToCountry: "",
      ShipToEmail: "",
      ShipToEmpID: "",
      ShipToFname: "",
      ShipToLname: "",
      ShipToLocaion: "",
      ShipToMailStop: "",
      ShipToPhoneNo: "",
      ShipToState: "",
      ShipToZipCode: "",
    },
  });

  useEffect(() => {
    if (apiData && userData) {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;

      setPayload((prevPayload) => ({
        ...prevPayload,
        CommonDataUpdate: {
          ...prevPayload.CommonDataUpdate,
          CarrierTrackingNo: apiData.TrackingId,
          UpdatedOn: formattedDate,
          PrefixFormat: userData.PrefixFormat,
        },
        Items: prevPayload.Items.map((item) => ({
          ...item,
          CarrierTrackingNo: apiData.TrackingId,
          // Weight: apiData.PackageDetails.Weight
        })),
        MasterData: {
          ...prevPayload.MasterData,
          // ship from :
          CarrierTrackingNo: apiData.TrackingId,
          RefCarrierTrackingNo: apiData.TrackingId,
          ShipFromAdd1: apiData.FromAddress.Address1,
          ShipFromAdd2: apiData.FromAddress.Address2,
          ShipFromBuilding: apiData.FromAddress.Building,
          ShipFromCity: apiData.FromAddress.City,
          ShipFromCompanyName: apiData.FromAddress.CompanyName,
          ShipFromCountry: apiData.FromAddress.Country,
          ShipFromEmail: apiData.FromAddress.Email,
          ShipFromPhoneNo: apiData.FromAddress.Phone,
          ShipFromState: apiData.FromAddress.State,
          ShipFromZipCode: apiData.FromAddress.Zipcode,

          // ship to :
          ShipToAdd1: apiData.ToAddress.Address1,
          ShipToAdd2: apiData.ToAddress.Address2,
          ShipToAliasName: apiData.ToAddress.FirstName,
          ShipToBuilding: apiData.ToAddress.Building,
          ShipToCity: apiData.ToAddress.City,
          ShipToCompanyName: apiData.ToAddress.CompanyName,
          ShipToCountry: apiData.ToAddress.Country,
          ShipToEmail: apiData.ToAddress.Email,
          ShipToFname: apiData.ToAddress.FirstName,
          ShipToLname: apiData.ToAddress.LastName,
          ShipToPhoneNo: apiData.ToAddress.Phone,
          ShipToState: apiData.ToAddress.State,
          ShipToZipCode: apiData.ToAddress.Zipcode,

          // package images
          PackageImages: {
            image1: `${image.image}`,
          },
        },
      }));
    }
  }, [apiData, userData]);

  useEffect(() => {
    if (apiData && userData) {
      imaylPackageCreate(payload).then((resp) => {
        if (resp.Message == "SUCCESS") {
          setLoading(false);
          setDetailsdata({
            ...detailsdata,
            refno: resp.Result.ReferenceNos,
            details: `The data from the uploaded image has been successfully saved to IMAYL. 
Tracking number (click to track)`,
          });
        }
      });
    }
  }, [payload]);

  // Access the camera and stream video
  useEffect(() => {
    const constraints = {
      video: props.opencamera,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setNumberOfCameras(stream.getVideoTracks().length);
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });

    return () => {
      stopCamera();
    };
  }, [props.opencamera]);

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  // Function to take a photo, convert to Base64, and send to API
  const takePhotoAndSendToAPI = async () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const photo = canvas.toDataURL("image/png");
      setImage({ image: photo });

      // Convert the photo to Base64
      const reader = new FileReader();
      reader.readAsDataURL(dataURLtoBlob(photo));
      reader.onloadend = async () => {
        const base64String = reader.result;
        setBase64(base64String); // Set the Base64 string
        try {
          setLoading(true);
          const response = await fetch(
            "https://asia-south1-eshipz-c360.cloudfunctions.net/xcycleOCR",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key":
                  "yfogKObuthnBLn4NfX5QdkV3Rz3eZRndObCHVsxXurKEPkGG7k3e5W6VET2Zpc5i9AtbSuYMuUqUQZqzz9PVF7yPcQokj7TyxztFDdBBR06alE8OSzBKemGhs6IjYFxF",
              },
              body: JSON.stringify({ image: base64String }), // Send Base64 string
            }
          );
          const data = await response.json();
          if (!data.error) {
            setApidata(data);
          }
          if (data.error) {
            const errorimage = {
              imageError: data.error,
            };
            props.imageErrorhandleChat(errorimage);
            setLoading(false);
            alert(data.error);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
    }
    setOpenCamera(false);
    props.handleCloseCamera();
  };

  const switchCamera = () => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length > 1) {
        const currentTrack = videoTracks[0];
        videoTracks[1].enabled = !currentTrack.enabled;
        videoTracks[0].enabled = !currentTrack.enabled;
      }
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    if (image) {
      props.CampictureToChat(image);
    }
  }, [image]);

  useEffect(() => {
    if (detailsdata) {
      props.handleDetailsTochat(detailsdata);
    }
  }, [detailsdata]);

  const handleCloseCamerapopup = () => {
    props.handleCloseCamera();
  };

  useEffect(() => {
    if (loading !== null) {
      props.handleLoading(loading);
    }
  }, [loading]);

  return (
    <div className="camera_main_div_cam_comp">
      <IoClose
        onClick={handleCloseCamerapopup}
        className="close_button_camera_popup_tag"
      />
      <div className="camera_holder_parent_div">
        <video className="camera_tag_div_apply_style" ref={videoRef} autoPlay />
      </div>
      {numberOfCameras > 1 && (
        <FcRotateCamera
          className="camer_switch_icon_tag"
          onClick={switchCamera}
        />
      )}

      <button
        className="take_photo_camera_button_tag"
        onClick={takePhotoAndSendToAPI}
      >
        Scan
      </button>
    </div>
  );
};

export default Cameracomponent;
