const imaylUrl = "https://api.shippercopilot.com";
const imaylendpoints = {
  Authenticate: "/imaylLogin/",
  masterApi: "/imaylConfig/",
  createPackage: "/imaylPackage/",
};

export async function ImaylLogin(imaylpayload) {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(`${imaylUrl}${imaylendpoints.Authenticate}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(imaylpayload),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function imaylMaster(masterpayload) {
  const storedToken = localStorage.getItem("token");
  const token = localStorage.getItem("accesstoken");
  masterpayload["imayl_token"] = `Bearer ${storedToken}`;
  try {
    const response = await fetch(`${imaylUrl}${imaylendpoints.masterApi}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(masterpayload),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function imaylPackageCreate(payload) {
  const storedToken = localStorage.getItem("token"); // Retrieve token with the same key
  const token = localStorage.getItem("accesstoken");
  payload["imayl_token"] = `Bearer ${storedToken}`;
  try {
    const response = await fetch(`${imaylUrl}${imaylendpoints.createPackage}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}
