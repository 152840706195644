// import React from "react";
// import shipperlogo from "../../Assets/shippercopilotlogo.png";
// import delayed from "../../Assets/delayed.png";
// import highpriorty from "../../Assets/highpriorty.png";
// import totalshipment from "../../Assets/totalshipment.png";
// import highvalue from "../../Assets/highvalue.png";
// import "./Prompt.css";

// const prompts = [
//   {
//     image: `${delayed}`,
//     text: " Delayed Shipments",
//   },
//   {
//     image: `${highpriorty}`,
//     text: "High Priority Shipments",
//   },
//   {
//     image: `${totalshipment}`,
//     text: "Total Shipments processed today",
//   },
//   {
//     image: `${highvalue}`,
//     text: "High Value Shipments",
//   },
// ];

// function Prompts(props) {

//   const handleClickPrompts = (value) => {
//     console.log('prompts',value)
//     props.handlePrompts(value);
//   };
//   return (
//     <div className="logo_and_predetermined_prompts_holder_tag d-flex flex-column align-items-center justify-content-start">
//       <img className="shipper_copilot_image_logo_tag_home" src={shipperlogo} />
//       <div className="prompts_binding_holder_div d-flex align-items-center justify-content-center flex-wrap">
//         {prompts.map((item) => {
//           return (
//             <div
//               className="prompt_box_main_div_home_page d-flex flex-column"
//               onClick={() => {
//                 handleClickPrompts(item.text);
//               }}
//             >
//               <img className="prompts_icons_images_tag_home" src={item.image} />
//               <div className="prompts_types_text_parent_div">
//                 <span className="prompt_text_tags_home">{item.text}</span>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// export default Prompts;



import React from "react";
import shipperlogo from "../../Assets/shippercopilotlogo.png";
import delayed from "../../Assets/delayed.png";
import highpriorty from "../../Assets/highpriorty.png";
import totalshipment from "../../Assets/totalshipment.png";
import highvalue from "../../Assets/highvalue.png";
import "./Prompt.css";

const prompts = [
  {
    id: 1, // Add a unique id to each prompt
    image: delayed,
    text: "Delayed Shipments",
  },
  {
    id: 2,
    image: highpriorty,
    text: "High Priority Shipments",
  },
  {
    id: 3,
    image: totalshipment,
    text: "Total Shipments processed today",
  },
  {
    id: 4,
    image: highvalue,
    text: "High Value Shipments",
  },
];

function Prompts(props) {
  const handleClickPrompts = (value) => {
    if (props.handlePrompts) {
      props.handlePrompts(value); 
    }
  };

  return (
    <div className="logo_and_predetermined_prompts_holder_tag d-flex flex-column align-items-center justify-content-start">
      <img className="shipper_copilot_image_logo_tag_home" src={shipperlogo} alt="Shipper Logo" />
      <div className="prompts_binding_holder_div d-flex align-items-center justify-content-center flex-wrap">
        {prompts.map((item) => (
          <div
            key={item.id} // Add a unique key here
            className="prompt_box_main_div_home_page d-flex flex-column"
            onClick={() => handleClickPrompts(item.text)}
          >
            <img className="prompts_icons_images_tag_home" src={item.image} alt={item.text} />
            <div className="prompts_types_text_parent_div">
              <span className="prompt_text_tags_home">{item.text}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Prompts;

