export const url = "https://api.shippercopilot.com";

export const endpoints = {
  login: "/login/",
  posts: "/openInference/",
  getChats: "/chats/",
  getChatDetails:'/chatDetails/?chat_id=',
  newChat: "/newChat/",
  deleteChat: "/deleteChatName/",
  editChatname:'/editChatName/'
};
