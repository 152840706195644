import React, { useContext, useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import "./SideMenue.css";
import toggle from "../../Assets/toggle.png";
import newchat from "../../Assets/newchat.png";
import shipperlogo from "../../Assets/shippercopilotlogo.png";
import shipreq from "../../Assets/shipreq.png";
import shipnow from "../../Assets/shipnow.png";
import track from "../../Assets/track.png";
import autoship from "../../Assets/autoship.png";
import Delete from "../../Assets/delete.png";
import order from "../../Assets/order.png";
import {
  changeChatnameApi,
  deleteChatApi,
  getChatsApi,
  getChatsDeatilsInfo,
} from "../../Service/Service";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

const navtext = [
  {
    image: `${shipperlogo}`,
    text: "ShipperCopilot",
  },
  {
    image: `${order}`,
    text: "Orders",
  },
  {
    image: `${shipreq}`,
    text: "Ship Request",
  },
  {
    image: `${shipnow}`,
    text: "Ship Now",
  },
  {
    image: `${track}`,
    text: "Track Now",
  },
  {
    image: `${autoship}`,
    text: "Quote Now",
  },
];

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function SideMenue(props) {
  const { unauthorized, setUnauthorized } = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [allChats, setAllChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [renameval, setRenameval] = useState(null);
  const [renameid, setRenameid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getAllchat();
  }, [props.postdata]);

  const getAllchat = () => {
    getChatsApi()
      .then((resp) => {
        if (resp.status === true) {
          setAllChats(resp.chats);
        }
      })
      .catch((error) => {
        // Check if the error message is equal to 401
        if (error.message === "401") {
          setUnauthorized(true);
          navigate("/unauth");
        } else {
          console.error("Error fetching chats:", error);
        }
      });
  };

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const newchatstyle = {
    marginLeft: windowWidth <= 500 ? "78%" : "3%",
  };

  const handleclickMenue = () => {
    props.handleclickedMenueBar();
  };

  const handleClickNewChat = () => {
    props.ClickedNewChat();
    getAllchat();
  };

  const handleSelectedChat = (value) => {
    if (value) {
      getChatsDeatilsInfo(value).then((resp) => {
        if (resp.status === true) {
          props.handleclickedChats(resp.chat, value);
        }
      });
    }
  };

  const handleClicksidenavprompts = (value) => {
    if (value) {
      handleClickNewChat();
    }
  };

  const categorizeChats = (chatDate) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    if (chatDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (chatDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else if (chatDate >= oneWeekAgo) {
      return "Last Week";
    } else if (chatDate >= oneMonthAgo) {
      return "Last Month";
    } else if (chatDate >= oneYearAgo) {
      return "Last Year";
    }
    return "Older";
  };

  const groupedChats = allChats.reduce((acc, chat) => {
    const chatDate = new Date(chat.created_time);
    const category = categorizeChats(chatDate);
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(chat);
    return acc;
  }, {});

  const handleToggleOptions = (chatId) => {
    setActiveChatId((prevChatId) => (prevChatId === chatId ? null : chatId)); // Toggle options on click
  };

  const handleClickDeleteChat = (value) => {
    deleteChatApi(value).then((resp) => {
      if (resp.status == true) {
        setRenameid(null);
        setRenameval(null);
        getAllchat();
        props.checkChatandClear(value);
      }
    });
  };

  const handleMouseleaveoption = () => {
    setActiveChatId(null);
  };

  const handleClickRename = (id, name) => {
    setRenameid(id);
    setRenameval(name);
  };
  const handleChangeRename = (e) => {
    setRenameval(e.target.value);
  };

  const handlSavedchanged = () => {
    if (renameid && renameval) {
      const renamepayload = {
        chat_name: `${renameval}`,
      };
      changeChatnameApi(renameid, renamepayload).then((resp) => {
        if (resp.status == true) {
          setRenameid(null);
          setRenameval(null);
          getAllchat();
        }
      });
    }
  };

 

  return (
    <div className="side_nav_bar_shipper_copilot_main_div">
      <div className="menue_toggle_ans_edit_main_div_holder d-flex align-items-center justify-content-start">
        <img
          className="toggle_image_side_navbar_tag"
          onClick={handleclickMenue}
          src={toggle}
          alt="loading"
        />
        <img
          className="newchat_image_side_navbar_tag"
          style={props.menue ? newchatstyle : null}
          src={newchat}
          onClick={handleClickNewChat}
          alt="loading"
        />
      </div>

      <div className="side_menue_text_shipper_copilot_types_div d-flex flex-column align-items-center justify-content-start">
        {navtext.map((items) => (
          <div
            key={items.text}
            className="side_menue_text_shipper_copilot_types_sub_div d-flex align-items-center"
            style={{
              borderBottom:
                items.text === "Ship Request" || items.text === "ShipperCopilot"
                  ? "0.15vw solid #D5D5D5"
                  : "none",
              borderRadius: "0",
            }}
            onClick={() => {
              handleClicksidenavprompts(items.text);
            }}
          >
            <img
              className="shipperlogoin_side_nav_bar-heading"
              src={items.image}
              alt={items.text}
            />
            <span>{items.text}</span>
          </div>
        ))}
      </div>

      <div className="side_menue_text_shipper_copilot_types_div chats_side_nav_scroll_parent d-flex flex-column align-items-center justify-content-start">
        {Object.keys(groupedChats).map((category) => (
          <div key={category} className="chat-category">
            <span className="catagory_tag_div_span">{category}</span>
            {groupedChats[category].map((chat) => {
              const chatDate = new Date(chat.created_time);
              return (
                <div
                  className="parent_chat_main_div_side_nav d-flex align-items-center"
                  onMouseLeave={handleMouseleaveoption}
                >
                  <div
                    key={chat.id}
                    className="side_menue_text_shipper_copilot_types_sub_div d-flex align-items-center justify-content-start"
                  >
                    {renameid && renameid == chat.id ? null : (
                      <span onClick={() => handleSelectedChat(chat.id)}>
                        {toTitleCase(chat.chat_name)}
                      </span>
                    )}
                    {renameid && renameid == chat.id ? (
                      <input
                        className="rename_input_tage_side_menue_chats"
                        type="text"
                        value={renameval}
                        onChange={handleChangeRename}
                        autoFocus
                        onBlur={handlSavedchanged}
                      />
                    ) : null}
                    <BsThreeDots
                      className="BsThreeDots_sidenav"
                      onClick={() => handleToggleOptions(chat.id)} // Toggle on click
                    />
                  </div>
                  {activeChatId === chat.id && (
                    <div className="rename_delete_parent_div d-flex flex-column">
                      <button
                        className="rename_button_tag"
                        onClick={() => {
                          handleClickRename(chat.id, chat.chat_name);
                        }}
                      >
                        Rename
                      </button>
                      <div className="d-flex align-items-center">
                        <img className="delete_icon_sidemenue" src={Delete} />
                        <span
                          className="delete_chat_button_sidemenue"
                          style={{ color: "red" }}
                          onClick={() => {
                            handleClickDeleteChat(chat.id);
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideMenue;
