import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Home from "./Views/Home/Home";
import Login from "./Views/Login/Login";
import { AuthContext } from "./AuthContext";
import UnAuthorized from "./Views/UnAuthorized/UnAuthorized";

const App = () => {
  
  const [unauthorized, setUnauthorized] = useState(false);
  

  return (
    <AuthContext.Provider value={{ unauthorized, setUnauthorized }}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <div className="Shipper_copilot_appjs_main_div">
                {/* <TopNav /> */}
                <Home />
                <Footer />
              </div>
            }
          />
         {unauthorized && <Route path="/unauth" element={<UnAuthorized />} />}
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
