// apiService.js
import { url, endpoints } from "./Config";

export async function LoginShipper(payload) {
  try {
    const response = await fetch(`${url}${endpoints.login}`, {
      method: "POST", // Change method to POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(payload), // Convert payload to JSON string and send in body
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

export async function postdataChat(payload, chatiD) {
  const token = localStorage.getItem("accesstoken");
  const chatpayload = {
    question: `${payload.question}`,
    chat_id: chatiD,
  };
  try {
    const response = await fetch(`${url}${endpoints.posts}`, {
      method: "POST", // Change method to POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(chatpayload), // Convert payload to JSON string and send in body
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function getchatIdnewchat(payload) {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(`${url}${endpoints.newChat}`, {
      method: "POST", // Change method to POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload), // Convert payload to JSON string and send in body
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function getChatsApi() {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(`${url}${endpoints.getChats}`, {
      method: "GET", // Ensure the method is correct
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // Throw an error with the status code if the response is not ok
      throw new Error(response.status); // Throw the actual status code
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function getChatsDeatilsInfo(payload) {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(
      `${url}${endpoints.getChatDetails}${payload}`,
      {
        method: "Get", // Change method to POST
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(payload), // Convert payload to JSON string and send in body
      }
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function deleteChatApi(value) {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(`${url}${endpoints.deleteChat}${value}`, {
      method: "DELETE", 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}

export async function changeChatnameApi(id,renamepayload) {
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await fetch(`${url}${endpoints.editChatname}${id}`, {
      method: "PATCH", // Change method to POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(renamepayload), // Convert payload to JSON string and send in body
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error; // Optionally re-throw the error
  }
}