import React from "react";
import "./UnAuthorized.css";
import session from "../../Assets/session.png";
import logo from "../../Assets/shippercopilotlogo.png";
import { useNavigate } from "react-router-dom";

function UnAuthorized() {
  const navigate = useNavigate();
  const handleClickedLoginnav = () => {
    navigate("/");
  };
  return (
    <div class="session_expired_parent_div">
      <div class="session_expired_main_logo">
        <img class="session_expired_image_1" src={logo} alt="loading..." />
      </div>
      <div class="session_expired_child_div">
        <img class="Session_expired_image" src={session} alt="loading..." />
        <span class="session_expired_text">Session expired</span>
        <button
          type="submit"
          class="session_expired_login_button"
          onClick={handleClickedLoginnav}
        >
          Go to Login
        </button>
      </div>
    </div>
  );
}

export default UnAuthorized;
