import React, { useEffect, useState } from "react";
import "./UploadDialogueBox.css";
import sap from "../../Assets/sap.png";
import sage from "../../Assets/sage.png";
import micro from "../../Assets/micro.png";
import salseforce from "../../Assets/salseforce.png";
import infor from "../../Assets/infor.png";
import oracle from "../../Assets/oracle.png";
import imayl from "../../Assets/imayl.png";
import ImaylLogin from "../../Views/ImaylLogin/ImaylLogin";
import Uploadimage from "../UploadImage/Uploadimage";
import Cameracomponent from "../Camera/Cameracomponent";
import camera from "../../Assets/camera.png";
import { imaylMaster } from "../../Service/ImaylService";

function UploadDialogueBox(props) {
  const [openimayl, setOpenimayl] = useState(false);
  const [opencamera, setOpenCamera] = useState(false);
  const storedToken = localStorage.getItem("token");
  const [rendermaster, setRenderMaster] = useState(false);
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");

  const connections = [
    {
      image: sap,
      text: "Connect to SAP S/4 HANA",
    },
    {
      image: sage,
      text: "Connect to Sage",
    },
    {
      image: micro,
      text: "Connect to Microsoft Dynamics 365",
    },
    {
      image: salseforce,
      text: "Connect to Salesforce",
    },
    {
      image: infor,
      text: "Connect to Infor M3",
    },
    {
      image: oracle,
      text: "Connect to Oracle EBS Cloud",
    },
    {
      image: imayl,
      text: "Connect to iMayl",
      connected: `${token ? "connected" : ""}`,
    },
    {
      image: storedToken ? camera : null,
      text: storedToken ? "Open Camera" : null,
    },
  ];

  const handleClickedimayl = () => {
    setOpenimayl(!openimayl);
  };

  const handleClickedImageBox = (value) => {
    if (value === "Connect to iMayl") {
      handleClickedimayl();
    }
    if (value == "Open Camera") {
      handleclickCamopen();
    }
  };

  const handleclickCamopen = () => {
    setOpenCamera(!opencamera);
  };
  const handleCloseLogin = () => {
    setOpenimayl(false);
    setRenderMaster(!rendermaster);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      masterApidata();
    }
  }, [rendermaster]);

  const masterApidata = () => {
    const masterpayload = {
      LoginId: "U00001",
      CompanyId: 4629510147,
    };
    imaylMaster(masterpayload).then((resp) => {
      setUserData(resp.Result.UserData);
    });
  };

  const handleImagetodataview = (value) => {
    props.handleimagetodata(value);
  };

  const handleImageToChat = (value) => {
    props.handleImageTochat(value);
  };

  const CampictureToChat = (value) => {
    props.cameraPicToChat(value);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
  };
  const handleDetailsTochat = (value) => {
    props.handleDetailsToChat(value);
  };

  const imageErrorhandleChat = (value) => {
    props.imageErrorTochat(value);
  };

  const closeImayllogin = () => {
    setOpenimayl(false);
  };

  const handleLoading = (value) => {
    props.handleloadigData(value);
  };

  return (
    <>
      <div style={{ display: opencamera ? "block" : "none" }}>
        {storedToken ? (
          <Cameracomponent
            userData={userData}
            CampictureToChat={CampictureToChat}
            handleCloseCamera={handleCloseCamera}
            handleDetailsTochat={handleDetailsTochat}
            imageErrorhandleChat={imageErrorhandleChat}
            handleLoading={handleLoading}
            opencamera={opencamera}
          />
        ) : null}
      </div>
      {openimayl && (
        <ImaylLogin
          handleCloseLogin={handleCloseLogin}
          closeImayllogin={closeImayllogin}
        />
      )}
      <div className="UploadDialogueBox_main_div d-flex flex-column">
        {connections.map((item, index) => (
          <div
            key={index}
            className="parent_div_connectionsparent_holder_div d-flex align-items-center"
            onClick={() => handleClickedImageBox(item.text)}
          >
            {item.image ? (
              <img
                className="image_size_uploaddialogue_box_tag"
                src={item.image}
                alt={item.text}
              />
            ) : null}
            <span className="image_size_uploaddialogue_box_text_tag">
              {item.text}
            </span>
            {item.connected ? (
              <span
                style={{ color: "green" }}
                className="image_size_uploaddialogue_box_text_tag"
              >
                {item.connected}
              </span>
            ) : null}
          </div>
        ))}

        {storedToken ? (
          <Uploadimage
            userData={userData}
            handleImagetodataview={handleImagetodataview}
            handleImageToChat={handleImageToChat}
            imageErrorhandleChat={imageErrorhandleChat}
            handleLoading={handleLoading}
          />
        ) : null}
      </div>
    </>
  );
}

export default UploadDialogueBox;
