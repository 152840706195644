import React, { useEffect, useState } from "react";
import { imaylMaster, imaylPackageCreate } from "../../Service/ImaylService";
import Upload from "../../Assets/upload.png";
import "./Uploadimage.css";

function Uploadimage(props) {
  const [apiData, setApidata] = useState(null);
  const [image, setImage] = useState(null);
  const userData = props.userData;
  const [imageResult, setImageResult] = useState(null);
  const [imageResp, setImageResp] = useState(false);
  const [loading, setLoading] = useState(null);

  const [payload, setPayload] = useState({
    CommonDataUpdate: {
      AppType: "Android",
      AppVersion: "1.0",
      CarrierTrackingNo: "",
      CompanyId: 4629510147,
      DeviceName: "RMX36867",
      LabelType: "3 * 2",
      Latitude: "17.563773",
      LoggedInLocation: "eshipjet",
      Longitude: "73.5399354",
      OSVersion: "14",
      PrefixFormat: "",
      PrefixRange: 5,
      SyncType: "Online",
      UpdatedBy: "U00097",
      UpdatedOn: "2024-07-17 22:02:19",
    },
    Items: [
      {
        Bin: "",
        CarrierTrackingNo: "",
        DeliveryLocation: "",
        InternalTrackingNo: "",
        Mailbox: "",
        Notes: "",
        PackageImages: {
          Image1: "",
          Image2: "",
          Image3: "",
          SignatureImage: "",
        },
        PackageTypeId: "",
        PoNo: "",
        ReasearchReason: "",
        RefCarrierTrackingNo: "",
        RefDate: "",
        ShipmentValue: "",
        StatusCode: "REC",
        StorageLocation: "",
        // Weight: "",
      },
    ],
    MasterData: {
      CarrierId: "",
      CarrierTrackingNo: "",
      PackageImages: {
        Image1: "",
        Image2: "",
        Image3: "",
      },
      RefCarrierTrackingNo: "",
      ShipFromAdd1: "",
      ShipFromAdd2: "",
      ShipFromBuilding: "",
      ShipFromCity: "",
      ShipFromCompanyName: "",
      ShipFromCountry: "",
      ShipFromEmail: "",
      ShipFromEmpID: "",
      ShipFromFname: "",
      ShipFromLname: "",
      ShipFromLocaion: "",
      ShipFromMailStop: "",
      ShipFromPhoneNo: "",
      ShipFromState: "",
      ShipFromZipCode: "",
      ShipToAdd1: "",
      ShipToAdd2: "",
      ShipToAliasName: "",
      ShipToBuilding: "",
      ShipToCity: "",
      ShipToCompanyName: "",
      ShipToCountry: "",
      ShipToEmail: "",
      ShipToEmpID: "",
      ShipToFname: "",
      ShipToLname: "",
      ShipToLocaion: "",
      ShipToMailStop: "",
      ShipToPhoneNo: "",
      ShipToState: "",
      ShipToZipCode: "",
    },
  });

  useEffect(() => {
    if (apiData && userData) {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;

      setPayload((prevPayload) => ({
        ...prevPayload,
        CommonDataUpdate: {
          ...prevPayload.CommonDataUpdate,
          CarrierTrackingNo: apiData.TrackingId,
          UpdatedOn: formattedDate,
          PrefixFormat: userData.PrefixFormat,
        },
        Items: prevPayload.Items.map((item) => ({
          ...item,
          CarrierTrackingNo: apiData.TrackingId,
          // Weight: apiData.PackageDetails.Weight
        })),
        MasterData: {
          ...prevPayload.MasterData,

          // ship from :
          CarrierTrackingNo: apiData.TrackingId,
          RefCarrierTrackingNo: apiData.TrackingId,
          ShipFromAdd1: apiData.FromAddress.Address1,
          ShipFromAdd2: apiData.FromAddress.Address2,
          ShipFromBuilding: apiData.FromAddress.Building,
          ShipFromCity: apiData.FromAddress.City,
          ShipFromCompanyName: apiData.FromAddress.CompanyName,
          ShipFromCountry: apiData.FromAddress.Country,
          ShipFromEmail: apiData.FromAddress.Email,
          ShipFromPhoneNo: apiData.FromAddress.Phone,
          ShipFromState: apiData.FromAddress.State,
          ShipFromZipCode: apiData.FromAddress.Zipcode,

          // ship to :
          ShipToAdd1: apiData.ToAddress.Address1,
          ShipToAdd2: apiData.ToAddress.Address2,
          ShipToAliasName: apiData.ToAddress.FirstName,
          ShipToBuilding: apiData.ToAddress.Building,
          ShipToCity: apiData.ToAddress.City,
          ShipToCompanyName: apiData.ToAddress.CompanyName,
          ShipToCountry: apiData.ToAddress.Country,
          ShipToEmail: apiData.ToAddress.Email,
          ShipToFname: apiData.ToAddress.FirstName,
          ShipToLname: apiData.ToAddress.LastName,
          ShipToPhoneNo: apiData.ToAddress.Phone,
          ShipToState: apiData.ToAddress.State,
          ShipToZipCode: apiData.ToAddress.Zipcode,

          // package images
          PackageImages: {
            image1: `${image.image}`,
          },
        },
      }));
    }
  }, [apiData, userData]);

  useEffect(() => {
    if (apiData && userData) {
      imaylPackageCreate(payload).then((resp) => {
        setLoading(false);
        setImageResp(false);
        setImageResult({
          ...imageResult,
          refno: `${resp.Result.ReferenceNos}`,
          details: `The data from the uploaded image has been successfully saved to IMAYL. 
Tracking number (click to track)`,
        });
      });
    }
  }, [payload]);

  useEffect(() => {
    if (imageResult) {
      props.handleImagetodataview(imageResult);
    }
  }, [imageResult]);

  useEffect(() => {
    if (image) {
      props.handleImageToChat(image);
    }
  }, [image]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageResp(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        setImage({ ...image, image: base64String });

        try {
          setLoading(true);
          const response = await fetch(
            "https://asia-south1-eshipz-c360.cloudfunctions.net/xcycleOCR",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key":
                  "yfogKObuthnBLn4NfX5QdkV3Rz3eZRndObCHVsxXurKEPkGG7k3e5W6VET2Zpc5i9AtbSuYMuUqUQZqzz9PVF7yPcQokj7TyxztFDdBBR06alE8OSzBKemGhs6IjYFxF", // Replace with your API key
              },
              body: JSON.stringify({ image: base64String }), // Send Base64 string
            }
          );

          const data = await response.json();
          if (!data.error) {
            setApidata(data);
          }
          if (data.error) {
            const errorimage = {
              imageError: data.error,
            };
            props.imageErrorhandleChat(errorimage);
            setLoading(false);
            alert(data.error);
          }
        } catch (error) {
          alert(error);
        }
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  useEffect(() => {
    if (loading !== null) {
      props.handleLoading(loading);
    }
  }, [loading]);

  return (
    <div>
      <label
        htmlFor="fileUpload"
        className="custom_file_upload d-flex align-items-center justify-content-center"
      >
        <div className="d-flex upload_image_image_and_text_parent_div">
          <img className="image_upload_file_from_computer" src={Upload} />
          <span>Upload from Computer </span>
        </div>
      </label>
      <input
        id="fileUpload"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        disabled={imageResp}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default Uploadimage;
