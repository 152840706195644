import React, { useState } from "react";
import { ImaylLogin as loginUser } from "../../Service/ImaylService";
import "./imaylLogin.css"; // Import the function from the service file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // FontAwesome icons
import { IoClose } from "react-icons/io5";


function ImaylLogin(props) {
  // State to manage email, password inputs, and password visibility
  const [payload, setPayload] = useState({
    email: "",
    password: "",
    SSOflag: false,
  });

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const storedToken = localStorage.getItem("token");

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await loginUser(payload);
      const tokenKey = response.Result.TokenKey;
      if (response.StatusCode === 200 && response.Result) {
        alert("iMayl Login Success");
        props.handleCloseLogin();
        localStorage.setItem("token", tokenKey);
      } else {
        alert(
          "iMayl Authentication Failed. Please check your credentials and try again"
        );
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  // Handle changes to the form inputs
  const handleChange = (event) => {
    const { id, value } = event.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [id]: value,
    }));
  };

  // Toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClickCloseImayllogin =()=>{
    props.closeImayllogin();
  }

  return (
    <div>
      {storedToken == undefined || storedToken == null ? (
        <div className="login_main_div">
          <div className="inside_box">
            <span className="imayl_text">Login to iMayl</span>
            <IoClose className="close_icon_imayl_login" onClick={handleClickCloseImayllogin} />
            <form onSubmit={handleSubmit}>
              <div className="Email_imayl">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email address"
                  required
                  className="login_user_input"
                  value={payload.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>

              <div className="password_imayl">
                <input
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  required
                  className="login_user_input"
                  value={payload.password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                  <FontAwesomeIcon  onClick={togglePasswordVisibility} className="password_eye_icon_imayl" icon={showPassword ? faEyeSlash : faEye} />
              </div>
              <button type="submit" className="Continue_btn">
                Continue
              </button>
            </form>
          </div>
        </div>
      ) : (
        null
      )}
    </div>
  );
}

export default ImaylLogin;
