// import React, { useEffect, useRef, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import shipperlogo from "../../Assets/shippercopilotlogo.png";
// import * as XLSX from "xlsx"; // Import xlsx library
// import "./DataComponent.css";
// import { FaDownload } from "react-icons/fa";

// const TypingEffect = ({ text }) => {
//   const [displayedText, setDisplayedText] = useState("");
//   const [index, setIndex] = useState(0);
//   useEffect(() => {
//     if (index < text.length) {
//       const timeoutId = setTimeout(() => {
//         setDisplayedText(displayedText + text[index]);
//         setIndex(index + 1);
//       }, 100); // Adjust the speed of typing here
//       return () => clearTimeout(timeoutId);
//     }
//   }, [index, text, displayedText]);

//   return <p>{displayedText}</p>;
// };

// const DataComponent = (props) => {
//   const { postdata } = props;
//   const posts = postdata;
//   const divRef = useRef(null);

//   const handleScrollToBottom = () => {
//     if (divRef.current) {
//       divRef.current.scrollTop = divRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     handleScrollToBottom();
//   }, [posts]);

//   const handleClickedRefno = (item) => {
//     console.log(item);
//     props.handleClickeditem(item);
//   };

//   // Function to download table data as Excel
//   const downloadTableAsExcel = (data, filename = "table_data.xlsx") => {
//     const worksheet = XLSX.utils.json_to_sheet(data);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//     XLSX.writeFile(workbook, filename);
//   };

//   const extractTableData = (post) => {
//     return post.message.map((item) =>
//       Object.keys(item).reduce((acc, key) => {
//         acc[key] = item[key] !== null ? item[key].toString() : "N/A";
//         return acc;
//       }, {})
//     );
//   };

//   return (
//     <div ref={divRef} className="text_table_data_component_main_div">
//       {posts.map((post, index) => (
//         <div key={index}>
//           {Array.isArray(post.message) ? (
//             <div className="parent_div_image_chat_header_question_text_main_div d-flex align-items-center justify-content-start">
//               <img
//                 className="shipper_copilot_logo_inside_the_chat"
//                 src={shipperlogo}
//                 alt="loading"
//               />
//               <span>{post.question}</span>
//             </div>
//           ) : null}

//           {Array.isArray(post.message) ? (
//             <div>
//               <div className="table_parent_scroll_bar_main_div">
//                 <Table className="table_main_div_table_data_holder">
//                   <TableHead className="table_head_div_tag">
//                     <TableRow>
//                       {Object.keys(post.message[0]).map((key) => (
//                         <TableCell
//                           key={key}
//                           className="table_cell_data_shipper_copilot"
//                         >
//                           {key.replace(/_/g, " ").toUpperCase()}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody className="table_body_div_tag">
//                     {post.message.map((item) => (
//                       <TableRow
//                         key={item.id}
//                         className="table_head_tag_thead_div"
//                       >
//                         {Object.keys(item).map((key) => (
//                           <TableCell
//                             key={key}
//                             className="table_cell_data_shipper_copilot"
//                           >
//                             {key === "image" ? (
//                               <img src={item[key]} alt="Shipment" />
//                             ) : item[key] !== null ? (
//                               item[key].toString()
//                             ) : (
//                               "N/A"
//                             )}
//                           </TableCell>
//                         ))}
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//                 <div className="download_excell_parent_holder">
//                   <FaDownload
//                     className="download_table_download_icon_style"
//                     onClick={() =>
//                       downloadTableAsExcel(
//                         extractTableData(post),
//                         "table_data.xlsx"
//                       )
//                     }
//                   />
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div className="image_parent_div">
//               {post.image ? (
//                 <div className="image_parent_div_showing_data_component d-flex">
//                   <img
//                     className="image_tag_data_load_component_tag"
//                     src={post.image}
//                     alt="Post Image"
//                   />
//                 </div>
//               ) : (
//                 <p>{post.message}</p>
//               )}
//               {post.question ? (
//                 <div className="question_inside_chat_ui_parent_div d-flex align-items-center justify-content-end">
//                   <span>{post.question}</span>
//                 </div>
//               ) : null}
//               {post.fetcherror ? <span>{post.fetcherror}</span> : null}
//               {post.imageError ? (
//                 <div className="image_details_text_div_parent_holers_logo_text d-flex  align-items-start">
//                   <img
//                     className="shipper_copilot_logo_inside_the_chat"
//                     src={shipperlogo}
//                     alt="loading"
//                   />
//                   <span className="post_image_error_ocr_text">
//                     {post.imageError}
//                   </span>
//                 </div>
//               ) : null}
//               {post.iframe ? (
//                 <div className="handle_iframe_parent_holder_in_chat">
//                   <iframe
//                     src={`https://stage.eshipjet.ai/imayl/itrack.html?itrackno=${post.iframe}`}
//                     style={{
//                       width: "100%",
//                       height: "500px",
//                       border: "none",
//                     }}
//                     title="Tracking Page"
//                   />
//                 </div>
//               ) : null}

//               {post.details ? (
//                 <div className="image_details_text_div_parent_holers_logo_text d-flex  align-items-start">
//                   <img
//                     className="shipper_copilot_logo_inside_the_chat"
//                     src={shipperlogo}
//                     alt="loading"
//                   />
//                   <div className="d-flex flex-column">
//                     <span className="image_details_text_inside_chat">
//                       {post.details}
//                     </span>
//                     <span className="image_details_text_inside_chat">
//                       <span
//                         onClick={() => {
//                           handleClickedRefno(post.refno);
//                         }}
//                         className="postdata_refgerence_no_tag"
//                       >
//                         {post.refno}
//                       </span>
//                     </span>
//                   </div>
//                 </div>
//               ) : null}
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DataComponent;



import React, { useEffect, useRef, useState } from "react";
import shipperlogo from "../../Assets/shippercopilotlogo.png";
import * as XLSX from "xlsx"; // Import xlsx library
import "./DataComponent.css";
import { FaDownload } from "react-icons/fa";

const TypingEffect = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(displayedText + text[index]);
        setIndex(index + 1);
      }, 100); // Adjust the speed of typing here
      return () => clearTimeout(timeoutId);
    }
  }, [index, text, displayedText]);

  return <p>{displayedText}</p>;
};

const DataComponent = (props) => {
  const { postdata } = props;
  const posts = postdata;
  const divRef = useRef(null);

  const handleScrollToBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [posts]);

  const handleClickedRefno = (item) => {
    console.log(item);
    props.handleClickeditem(item);
  };

  // Function to download table data as Excel
  const downloadTableAsExcel = (data, filename = "table_data.xlsx") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  const extractTableData = (post) => {
    return post.message.map((item) =>
      Object.keys(item).reduce((acc, key) => {
        acc[key] = item[key] !== null ? item[key].toString() : "N/A";
        return acc;
      }, {})
    );
  };

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div ref={divRef} className="text_table_data_component_main_div">
      {posts.map((post, index) => (
        <div key={index}>
          {Array.isArray(post.message) ? (
            <div className="parent_div_image_chat_header_question_text_main_div d-flex align-items-center justify-content-start">
              <img
                className="shipper_copilot_logo_inside_the_chat"
                src={shipperlogo}
                alt="loading"
              />
              <span>{post.question}</span>
            </div>
          ) : null}

          {Array.isArray(post.message) ? (
            <div>
            <div className="table_parent_scroll_bar_main_div">
              <table className="table_main_div_table_data_holder">
                <thead className="table_head_div_tag">
                  <tr>
                    {/* Ensure shipment_id is rendered first */}
                    <th
                      key="shipment_id"
                      className="table_cell_data_shipper_copilot sticky-column"
                      style={{ backgroundColor: "#d8d8d8" }}
                    >
                      {toTitleCase("shipment id")}
                    </th>
                    {/* Render remaining keys except shipment_id */}
                    {Object.keys(post.message[0])
                      .filter((key) => key !== "shipment_id")
                      .map((key) => (
                        <th
                          key={key}
                          className="table_cell_data_shipper_copilot"
                          style={{ backgroundColor: "#d8d8d8" }}
                        >
                          {toTitleCase(key.replace(/_/g, " "))}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="table_body_div_tag">
                  {post.message.map((item) => (
                    <tr key={item.id} className="table_head_tag_thead_div">
                      {/* Render shipment_id first and make it sticky */}
                      <td key="shipment_id" className="table_cell_data_shipper_copilot sticky-column">
                        {item["shipment_id"] ? item["shipment_id"].toString() : "N/A"}
                      </td>
                      {/* Render remaining data except shipment_id */}
                      {Object.keys(item)
                        .filter((key) => key !== "shipment_id")
                         .map((key) => (
                          <td
                            key={key}
                            className="table_cell_data_shipper_copilot"
                          >
                            {key === "image" ? (
                              <img src={item[key]} alt="Shipment" />
                            ) : item[key] !== null ? (
                              item[key].toString()
                            ) : (
                              "N/A"
                            )}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="download_excell_parent_holder">
              <FaDownload
                className="download_table_download_icon_style"
                onClick={() =>
                  downloadTableAsExcel(extractTableData(post), "table_data.xlsx")
                }
              />
            </div>
          </div>
          
          ) : (
            <div className="image_parent_div">
              {post.image ? (
                <div className="image_parent_div_showing_data_component d-flex">
                  <img
                    className="image_tag_data_load_component_tag"
                    src={post.image}
                    alt="Post Image"
                  />
                </div>
              ) : null}

              {/* Render post.message only if it's a string */}
              {typeof post.message === "string" && (
                <div className="parent_div_image_chat_header_question_text_main_div d-flex align-items-center justify-content-start">
                  <img
                    className="shipper_copilot_logo_inside_the_chat"
                    src={shipperlogo}
                    alt="loading"
                  />
                  <span>{post.message}</span>
                </div>
              )}

              {post.question ? (
                <div className="question_inside_chat_ui_parent_div d-flex align-items-center justify-content-end">
                  <span>{post.question}</span>
                </div>
              ) : null}

              {post.fetcherror ? <span>{post.fetcherror}</span> : null}
              {post.imageError ? (
                <div className="image_details_text_div_parent_holers_logo_text d-flex  align-items-start">
                  <img
                    className="shipper_copilot_logo_inside_the_chat"
                    src={shipperlogo}
                    alt="loading"
                  />
                  <span className="post_image_error_ocr_text">
                    {post.imageError}
                  </span>
                </div>
              ) : null}

              {post.iframe ? (
                <div className="handle_iframe_parent_holder_in_chat">
                  <iframe
                    src={`https://stage.eshipjet.ai/imayl/itrack.html?itrackno=${post.iframe}`}
                    style={{
                      width: "100%",
                      height: "500px",
                      border: "none",
                    }}
                    title="Tracking Page"
                  />
                </div>
              ) : null}

              {post.details ? (
                <div className="image_details_text_div_parent_holers_logo_text d-flex  align-items-start">
                  <img
                    className="shipper_copilot_logo_inside_the_chat"
                    src={shipperlogo}
                    alt="loading"
                  />
                  <div className="d-flex flex-column">
                    <span className="image_details_text_inside_chat">
                      {post.details}
                    </span>
                    <span className="image_details_text_inside_chat">
                      <span
                        onClick={() => {
                          handleClickedRefno(post.refno);
                        }}
                        className="postdata_refgerence_no_tag"
                      >
                        {post.refno}
                      </span>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DataComponent;
