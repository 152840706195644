import React, { useEffect, useRef, useState } from "react";
import SideMenue from "../../Components/SideMenue/SideMenue";
import "./Home.css";
import Prompts from "../../Components/Prompts/Prompts";
import linkicon from "../../Assets/linkicon.png";
import { FaArrowUp } from "react-icons/fa6";
import DataComponent from "../../Components/DataComponent/DataComponent";
import { getchatIdnewchat, postdataChat } from "../../Service/Service";
import UploadDialogueBox from "../../Components/UploadDialogueBox/UploadDialogueBox";

function Home() {
  const [menue, SetMenue] = useState(true);
  const [postdata, setPostData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [connectionbox, setConnectionBox] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [inputvalue, setInputvalue] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatid, setChatid] = useState(null);
  const [payload, setPayload] = useState({
    question: "",
  });

  const navstyle = {
    position: "absolute",
    right: "0%",
    height: "95%",
    background: "#FFFFFF",
    width: "100%",
  };

  const shippertext = {
    marginLeft: "5%",
  };

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    if (hour >= 5 && hour < 12) {
      setGreeting("Good Morning");
    } else if (hour >= 12 && hour < 17) {
      setGreeting("Good Afternoon");
    } else if (hour >= 17 && hour < 21) {
      setGreeting("Good Evening");
    } else {
      setGreeting("Good Night");
    }
  }, []);

  const handleclickedMenueBar = () => {
    SetMenue(!menue);
  };

  const handleClickSend = () => {
    const updatedPostData = [...postdata, payload];
    setPostData(updatedPostData);
    if (postdata.length <= 0 && chatid == null) {
      const chatpayload = {
        chat_name: `${payload.question}`,
      };
      getchatIdnewchat(chatpayload).then((resp) => {
        if (resp.status == true) {
          const chatid = resp.chat_id;
          setChatid(chatid);
          postDataApiopeninfe(chatid);
        }
      });
    } else {
      postDataApiopeninfe(chatid);
    }
  };

  const postDataApiopeninfe = (chatid) => {
    const chatiD = chatid;
    setLoading(true);
    setPayload({ ...payload, question: "" });
    postdataChat(payload, chatiD).then((resp) => {
      if (resp.status === true) {
        setLoading(false);
        setPostData((prevPostData) => [...prevPostData, resp]);
        const dynamicColumns = Object.keys(resp.message[0]);
        setColumns((prevColumns) => [...prevColumns, ...dynamicColumns]);
      } else if (resp.status === false) {
        setPostData((prevPostData) => [...prevPostData, resp]);
        setLoading(false);
      }
    });
  };

  const handleloadigData = (value) => {
    if (value == true) {
      setLoading(true);
    }
    if (value == false) {
      setLoading(false);
    }
  };

  const handleclickLink = () => {
    setConnectionBox(!connectionbox);
  };

  const handlechangeEnterText = (e) => {
    setPayload({
      ...payload,
      question: e.target.value,
    });
  };

  useEffect(() => {
    if (postdata.length > 0) {
      setError(null); // Auto scroll when postdata changes
    }
  }, [postdata]);

  useEffect(() => {
    if (error) {
      setPostData([]);
      setColumns([]);
      setInputvalue(null);
    }
  }, [error]);

  const handleimagetodata = (value) => {
    setPostData((prevPostData) => [...prevPostData, value]);
  };

  const handleImageTochat = (value) => {
    if (value) {
      setPostData((prevPostData) => [...prevPostData, value]);
      setConnectionBox(false);
    }
  };

  const cameraPicToChat = (value) => {
    setPostData((prevPostData) => [...prevPostData, value]);
    setConnectionBox(false);
  };

  const handleClickeditem = (value) => {
    const iframe = {
      iframe: value,
    };
    setPostData((prevPostData) => [...prevPostData, iframe]);
  };

  const handleDetailsToChat = (value) => {
    setPostData((prevPostData) => [...prevPostData, value]);
  };
  const imageErrorTochat = (value) => {
    setPostData((prevPostData) => [...prevPostData, value]);
  };
  const [prompts, setPrompts] = useState(0);

  const handlePrompts = (value) => {
    const prompts = {
      question: value,
    };
    setPayload({ ...payload, question: `${value}` });
    // setPostData((prevPostData) => [...prevPostData, prompts]);
    setPrompts((prev) => prev + 1);
  };

  useEffect(() => {
    if (prompts > 0) {
      handleClickSend();
    }
  }, [prompts]);

  const ClickedNewChat = () => {
    setPostData([]);
    setChatid(null);
  };
  const handleclickedChats = (value,chatid) => {
    setPostData([]);
    setChatid(chatid)
    if (value) {
      value.map((item) => {
        setPostData((prevPostData) => [...prevPostData, item]);
      });
    }
  };
const checkChatandClear =(value)=>{
  if(value == chatid){
    setPostData([]);
    setChatid(null)
  }}


  return (
    <div className="shipper_copilot_home_main_div d-flex">
      <SideMenue
        handleclickedMenueBar={handleclickedMenueBar}
        menue={menue}
        postdata={postdata}
        ClickedNewChat={ClickedNewChat}
        handleclickedChats={handleclickedChats}
        checkChatandClear={checkChatandClear}
      />
      <div
        className="sub_main_div_home_shipper_copilot d-flex flex-column align-items-center"
        style={menue ? navstyle : null}
      >
        <div className="shipper_co_pilto_text_headingh_home_div d-flex align-items-center">
          <span
            className="shipper_co_pilto_text_headingh_home"
            style={menue && menue ? shippertext : null}
          >
            ShipperCopilot 6.0
          </span>
        </div>
        <div className="shipper_copilot_chat_text_area_main_div_home d-flex flex-column align-items-center justify-content-start">
          <div
            style={{ overflowY: "auto" }}
            className="shipper_copilot_chat_text_area_main_div_home_sub_div d-flex flex-column align-items-center justify-content-start"
          >
            {postdata.length > 0 || error ? (
              <DataComponent
                postdata={postdata}
                columns={columns}
                title={inputvalue ? inputvalue : null}
                error={error}
                handleClickeditem={handleClickeditem}
              />
            ) : (
              <Prompts handlePrompts={handlePrompts} />
            )}
            {loading ? (
              <span className="loading_text_tag_home">
                Analyzing data, please wait...
              </span>
            ) : null}
          </div>
          <div className="text_chat_displya_div_parent_input_holder d-flex align-items-center justify-content-center">
            <div style={{ display: connectionbox ? "block" : "none" }}>
              <UploadDialogueBox
                handleimagetodata={handleimagetodata}
                handleImageTochat={handleImageTochat}
                cameraPicToChat={cameraPicToChat}
                handleDetailsToChat={handleDetailsToChat}
                imageErrorTochat={imageErrorTochat}
                handleloadigData={handleloadigData}
              />
            </div>
            <img
              className="linkicon_hompage_input_icon"
              src={linkicon}
              alt="Link icon"
              onClick={loading ? null : handleclickLink}
            />
            <input
              className="input_fiels_shippercopiolet_chat_input_tag"
              type="text"
              placeholder={`${greeting} Alex, I'm ready for the task`}
              value={payload.question}
              disabled={loading}
              autoFocus
              onChange={handlechangeEnterText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClickSend();
                }
              }}
            />
            <button
              className="send_button_arrow_button"
              onClick={handleClickSend}
            >
              <FaArrowUp className="uparrow_send_button_div" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
