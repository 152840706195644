import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className='footer_main_div_shipper_copilot d-flex align-items-center justify-content-center'>
       <span>©️ 2024 eshipjet.ai, Inc. All Rights Reserved</span>
    </div>
  )
}

export default Footer