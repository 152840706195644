import React, { useEffect, useState } from "react";
import "./Login.css";
import shipperlogo from "../../Assets/shippercopilotlogo.png";
import { useNavigate } from "react-router-dom";
import { LoginShipper } from "../../Service/Service";

function Login() {
  // State to manage the payload
  const [payload, setPayload] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  useEffect(() => {
    localStorage.clear();
  }, []);
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    LoginShipper(payload).then((resp) => {
      if (resp.status == true) {
        localStorage.setItem("accesstoken", resp.access);
        navigate("/home");
      }
      if (resp.status == false) {
        alert("user not valid");
      }
    });
  };

  return (
    <div className="eship_jetai_parent_main_div">
      <div className="eship_jet_parent_child_div">
        <img src={shipperlogo} alt="eship_logo" className="eship_logo" />
      </div>
      <div className="eship_jetai_login_main_div">
        <span className="eship_login_title">Welcome back</span>
        <div className="form_div_login_eshipjet">
          <form onSubmit={handleSubmit}>
            <div className="form_group">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email address"
                className="eship_input"
                value={payload.email}
                onChange={handleChange}
              />
            </div>
            <div className="form_group">
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                className="eship_input"
                value={payload.password}
                onChange={handleChange}
              />
            </div>
            <div className="forgot_password_div">
              <span>Forgot Password ?</span>
            </div>
            <div className="form_eship_button_div">
              <button type="submit" className="form_eship_login_button">
                Continue
              </button>
            </div>

            <div className="eship_form_dont-haveaccount">
              <span className="dont-have_account_text">
                Don't have an account?
              </span>
              <span className="singup_text_eship">
                &nbsp;&nbsp;&nbsp;&nbsp;Signup
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
